<script>
import ValidationTemplate from '@/components/general/ValidationTemplate'
export default {
  name: 'InputField',
  components: { ValidationTemplate },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    validation: {
      type: Object
    },
    manualValidation: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ''
    },
    hideValidation: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    buttonHeight: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String
    }
  },
  data: () => ({ internalType: 'text', showPassword: false }),
  methods: {
    updateValidation () {
      if (!this.manualValidation && this.validation) this.validation.$touch()
    },
    changeInputType () {
      this.showPassword = !this.showPassword
      this.internalType = !this.showPassword ? 'password' : 'text'
    },
    showModal () {
    }
  },
  created () {
    this.internalType = this.type
  }
}
</script>
<template>
  <div class="input-field--wrapper" :class="{'small': small, 'primary-color': primary, 'button-height': buttonHeight}">
    <validation-template
      :validation="validation"
      :manualValidation="manualValidation"
      :hideValidation="hideValidation"
    >
      <v-text-field
        :type="internalType"
        class="body--1"
        :class="contentClass"
        v-bind="$attrs"
        v-on="$listeners"
        attach
        @blur="updateValidation"
        hide-details="auto"
        v-mask="mask"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        <template v-if="this.type === 'password'" v-slot:append>
          <v-icon @click="changeInputType">{{ `mdi-eye${showPassword ? '-off' : ''}` }}</v-icon>
        </template>
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      </v-text-field>
    </validation-template>
  </div>
</template>
<style lang="scss">
.input-field--wrapper {
  &.small {
    .v-input input {
      padding: 0;
    }
    .v-text-field--outlined .v-label {
      top: 14px;
    }
    .v-input--is-label-active .v-label,
    .v-input--is-focused .v-label {
      top: 18px;
    }
    .v-text-field--outlined>.v-input__control>.v-input__slot {
      min-height: 48px;
    }
    .v-text-field--enclosed .v-input__append-inner {
      margin-top: 12px;
    }
  }
  .v-input--is-readonly {
    height: 55px;
    background-color: $neutral-light
  }
  &.primary-color {
    .theme--light.v-text-field--outlined>.v-input__control>.v-input__slot fieldset,
    .v-text-field--outlined > .v-input__control > .v-input__slot {
      border-color: $primary-medium-faded !important;
    }
  }
  &.button-height {
    height: 40px;
    .v-text-field--outlined .v-label {
      top: 10px;
    }
    .v-input--is-readonly {
      height: 40px;
      color: $neutral-dark;
      &.v-text-field--outlined>.v-input__control>.v-input__slot {
        background-color: $neutral-light;
        input {
          color: $neutral-medium;
        }
      }
    }
    .v-text-field--outlined>.v-input__control>.v-input__slot {
      height: 40px;
      min-height: 40px;
    }
  }
}
</style>
